<template>
  <div class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество категорий</b-tooltip>
      <b-btn class='paycount' size="sm" v-on:click="querytabledata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid>
      <b-row>
        <b-col md="2" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Поиск" size="sm" />
              <b-input-group-append>
                <b-btn size="sm" :disabled="!filter" @click="filter = ''">Clear</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
      >
      </b-table>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination pagination-sm :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'servicegroups',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      //{ label: '#', key: 'index', 'class': 'text-center'},
      { label: 'Наименование', key: "name", sortable: true, 'class': 'text-left' },
      { label: 'Кол. сервисов', key: "count", sortable: true, 'class': 'text-center' },
    ],
    selected: 'false',
    options: [
      { text: 'В работе', value: 'false' },
      { text: 'Удаленные', value: 'true' }
    ],
    currentPage: 1,
    perPage: 40,
    totalRows: 0,
    totalsum: 0,
    pageOptions: [ 20, 40, 80 ],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null
  }),
  computed: {
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    expandAdditionalInfo(row) {
      //row.toggleDetails
      row._showDetails = !row._showDetails;
    },
    querytabledata () {
      axios
          .post(`${config.apiUrl}/query/getdata`,{DB: 'prodDB', type:['Servicegroups'], token: this.$store.state.authentication.user.token})
          .then(response => {
                  this.items = response.data;
                  this.totalRows = this.items.length;
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
    }
  },

  created() {
    this.querytabledata ()
  }
}
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
